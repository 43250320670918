import { useEffect, useState } from "react";
import "./App.css";
import newStatistics from "./db/2022.json";
import oldStatistics from "./db/2021.json";
import axios from "axios";

const roles = [
  { key: "P", label: "Portieri" },
  { key: "D", label: "Difensori" },
  { key: "C", label: "Centrocampisti" },
  { key: "A", label: "Attaccanti" },
];

const years = ["2022/23", "2021/22"];

const App = () => {
  const [query, setQuery] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [sort, setSort] = useState(null);
  const [year, setYear] = useState("2022/23");
  const [activePlayer, setActivePlayer] = useState(null);

  const players = year === "2022/23" ? newStatistics : oldStatistics;
  const thead = Object.keys(players[0]);

  return (
    <div className="App">
      {!!activePlayer && (
        <PlayerDetails playerId={activePlayer} setActivePlayer={setActivePlayer} />
      )}
      <div style={{ gap: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <input
          type="text"
          onChange={(ev) => setQuery(ev.target.value)}
          value={query}
          placeholder="Cerca calciatore..."
          style={{ margin: "16px 0", width: "30vw" }}
        />
        <select onChange={(ev) => setRoleFilter(ev.target.value)}>
          <option value={""}>Tutti</option>
          {roles.map((role) => (
            <option value={role.key}>{role.label}</option>
          ))}
        </select>
        <select onChange={(ev) => setYear(ev.target.value)} value={year}>
          {years.map((year) => (
            <option value={year}>{year}</option>
          ))}
        </select>
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          {thead.map((key) => (
            <th onClick={() => setSort(key)} style={{ cursor: "pointer" }}>
              {key}
            </th>
          ))}
          <th></th>
        </thead>
        <tbody>
          {players
            .filter(
              (player) =>
                player.Nome.toLowerCase().includes(query.toLowerCase()) ||
                player.Squadra.toLowerCase().includes(query.toLowerCase())
            )
            .filter((player) => player.R.includes(roleFilter))
            .sort((a, b) => (!sort ? 1 : b[sort] - a[sort]))
            .map((player) => (
              <PlayerRow data={player} setActivePlayer={setActivePlayer} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;

const PlayerRow = ({ data, setActivePlayer }) => {
  const columns = Object.values(data);
  return (
    <tr>
      {columns.map((value) => (
        <td>{value}</td>
      ))}
      <td>
        <button onClick={() => setActivePlayer(data.Id)}>Dettagli</button>
      </td>
    </tr>
  );
};

const PlayerDetails = ({ playerId, setActivePlayer }) => {
  const player = newStatistics.find((stat) => stat.Id === playerId);
  const oldStatistic = oldStatistics.find((stat) => stat.Id === playerId);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "90vw",
        height: "90vh",
        margin: "5vh 5vw",
        borderRadius: 16,
        overflow: "hidden",
        backgroundColor: "white",
        boxShadow: "0px 0px 28px 0px rgba(0,0,0,0.65)",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          // backgroundColor: "red"
        }}
      >
        <PlayerInfo data={player} setActivePlayer={setActivePlayer} />
      </div>
      <div
        style={{
          flex: 1,
          // backgroundColor: "blue",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <PlayerStatistics year="2021/2022" data={oldStatistic} />
      </div>
      <div
        style={{
          flex: 1,
          // backgroundColor: "green",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <PlayerStatistics year="2022/2023" data={player} />
      </div>
    </div>
  );
};

const PlayerStatistics = ({ data, year }) => {
  return (
    <div style={{}}>
      <h1>{year}</h1>
      {data ? <Statistics array={stats} data={data} /> : "Nessun dato su questa stagione"}
    </div>
  );
};

const stats = [
  // { label: "Squadra", key: "Squadra" },
  { label: "Partite a voto", key: "Pv" },
  { label: "Media voto", key: "Mv" },
  { label: "Media Fantacalcio", key: "Mf" },
  { label: "Gol fatti", key: "Gf" },
  { label: "Gol subiti", key: "Gs" },
  { label: "Assist", key: "Ass" },
  { label: "Autogol", key: "Au" },
  { label: "Ammunizioni", key: "Amm" },
  { label: "Espulsioni", key: "Esp" },
  { label: "Rigori parati", key: "Rp" },
  { label: "Rigori calciati", key: "Rc" },
  { label: "Rigori segnati", key: "R+" },
  { label: "Rigori sbagliati", key: "R-" },
];

const Statistics = ({ data, array }) => {
  return (
    <div>
      {array.map((stat) => (
        <div style={{ margin: "16px 0", fontSize: 24 }}>
          {stat.label} <b>{data[stat.key] || data.Fm}</b>
        </div>
      ))}
    </div>
  );
};

const infos = [
  { label: "Nome", key: "Nome" },
  { label: "Squadra", key: "Squadra" },
  { label: "Ruolo", key: "R" },
];

const PlayerInfo = ({ data, setActivePlayer }) => {
  const [timer, setTimer] = useState(false);
  const [value, setValue] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (data.Nome && data.Squadra) {
      getImage(data.Nome, data.Squadra);
    }
  }, []);

  const getImage = async (nome, squadra) => {
    try {
      const res = await axios({
        url: "https://www.googleapis.com/customsearch/v1",
        params: {
          key: "AIzaSyDkn7br27VyKUK-qR4LtxWjeA1JTmaH3Q4",
          cx: "76e1f6295774643b8",
          q: `${nome} ${squadra}`,
          searchType: "image",
        },
      });
      if (res.status === 200 && !!res.data) {
        const items = res.data.items;
        if (!!items && !!items.length) {
          setImage(items[0].link);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ padding: 24 }}>
      <button
        onClick={() => setActivePlayer(null)}
        style={{ position: "absolute", top: 16, right: 16, cursor: "pointer" }}
      >
        X
      </button>
      <div>
        {!!image && (
          <img src={image} style={{ height: "40vh", maxWidth: "25vw", objectFit: "contain" }} />
        )}
      </div>
      <div>
        <Statistics array={infos} data={data} />
      </div>
      {!timer ? (
        <div>
          <input
            placeholder="Valore partenza"
            type="number"
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
          />
          <button disabled={!value} onClick={() => setTimer(true)}>
            Avvia asta
          </button>
        </div>
      ) : (
        <div style={{ marginTop: "5vh" }}>
          <h1>{value}</h1>
          <Timer value={value} />
          <div style={{ display: "flex", justifyContent: "center", gap: 16, marginTop: 32 }}>
            <button style={{ fontSize: 24 }} onClick={() => setValue(Number(value) + 1)}>
              +1
            </button>
            <button style={{ fontSize: 24 }} onClick={() => setValue(Number(value) + 5)}>
              +5
            </button>
            <button style={{ fontSize: 24 }} onClick={() => setValue(Number(value) + 10)}>
              +10
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Timer = (props) => {
  const lsInitialMinutes = localStorage.getItem("initialMinutes");
  const lsInitialSeconds = localStorage.getItem("initialSeconds");

  const { initialMinute = lsInitialMinutes || 0, initialSeconds = lsInitialSeconds || 30 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    setMinutes(lsInitialMinutes || 0);
    setSeconds(lsInitialSeconds || 30);
  }, [props.value]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <h1>Venduto</h1>
      ) : (
        <h1>
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      )}
    </div>
  );
};
